<template>
    <div class="home">
        <van-nav-bar class="navbar" title="许可证信息填写" />
        <van-notice-bar wrapable :scrollable="false" text="提示：请使用和订阅支付一致的手机号码登陆下载许可证，如需帮助，请联系客服：17786083608" />
        <van-form @submit="onSubmit">
            <van-field class="myvanCell machineStyle" :required="true" v-model="machineCode" name="机器码" label="机器码"
                :readonly="true" type="textarea" rows="2" />

            <div class="myvanCell">
                <!-- <van-cell :value="cellTitle" is-link @click="onActive"> -->
                <van-cell :value="cellTitle">
                    <template slot="title">
                        <span class="custom-text">产品</span>
                    </template>
                </van-cell>
                <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" @select="onSelect"
                    @cancel="onCancel" name="ssd" />

                <!-- <van-cell :value="trialVersionTitle" is-link @click="onActiveTrialVersion">
                    <template slot="title">
                        <span class="custom-text">是否为试用版</span>
                    </template>
                </van-cell> -->
                <van-cell :value="trialVersionTitle">
                    <template slot="title">
                        <span class="custom-text">版本</span>
                    </template>
                </van-cell>
            </div>

            <div class="myvanCell">
                <van-action-sheet v-model="showVersion" :actions="actionsTrialVersion" cancel-text="取消"
                    @select="onSelectTrialVersion" @cancel="onCancelTrialVersion" name="ssb" />
                <van-field v-model="phoneNum" name="手机号码" label="手机号码" :required="true"
                    :rules="[{ pattern, message: '请输入正确的手机号' }]" @input="usernameInput()">
                    <!-- <template #button>
                        <van-button size="small" type="primary" :disabled="disable" @click.prevent="sendCode">{{ yzm
                            }}</van-button>
                    </template> -->
                </van-field>
                <!-- <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">

                </van-field> -->
            </div>

            <div class="myvanCell">
                <van-field v-model="name" name="姓名" label="姓名" :required="true" :rules="[{ required: true }]" />
                <van-field v-model="company" name="单位" label="单位" :required="true" :rules="[{ required: true }]" />
                <!-- <van-field v-model="inviteCode" name="邀请码(选填)" label="邀请码(选填)" /> -->
            </div>

            <div class="myvanCell">
                <van-field v-model="permitcode" name="许可码" label="许可码">
                    <template #right-icon>
                        <van-icon name="clear" v-show="!permitRes && permitcode" color="red" />
                        <van-icon name="checked" v-show="permitRes" color="green" />
                    </template>
                    <template #button>

                        <van-button size="small" type="primary" @click.prevent="checkPermit"> 查询 </van-button>
                    </template>
                </van-field>
                <van-field v-model="des" rows="3" label="备注" placeholder="请填写" type="textarea" maxlength="50"
                    show-word-limit />
            </div>
            <div style="margin: 16px;display: flex;">
                <van-button round block type="info" native-type="submit" :disabled="disabled" v-if="!permitRes"
                    style="margin-bottom: 10px;margin-right: 20px;">许可证申请</van-button>
                <van-button round block type="info" @click.prevent="createLicense" :disabled="disabled" v-else
                    style="margin-bottom: 10px;margin-right: 20px;">许可证生成</van-button>
                <van-button round block type="info" @click.prevent="searchLicense"
                    :disabled="disabled">许可证查询</van-button>
                <!-- <van-button round block type="info" @click="rewrite">重新填写</van-button> -->
            </div>
        </van-form>
    </div>
</template>

<script>
import { getUserIdByPhone, getLicensesByPhone } from "@/assets/API/pay.js";
import {
    SendVerCode, ApplyLicense, checkPermit
} from "@/assets/API/login";
import "amfe-flexible/index";
import { Notify, Dialog } from 'vant';
import { CheckVerCode } from "../../assets/API/login";
import request from "@/utils/request";

export default {
    name: "Home",
    data() {
        return {
            softName: '',
            urlData: {},
            machineCode: '',
            phoneNum: '',
            name: '',
            company: '',
            permitcode: '',
            inviteCode: '',
            des: '',
            sms: '',
            pattern: /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[0-9])\d{8}$/,
            yzm: "获取验证码",
            totalTime: 60,
            disable: true,
            show: false,
            cellTitle: "请选择产品",
            showVersion: false,
            trialVersionTitle: "请选择版本",
            type: "",
            durationRes: 0,
            actions: [
                {
                    name: "SummitMap",
                    value: 1,
                },
                {
                    name: "SummitSolid",
                    value: 2,
                },
                {
                    name: "SummitTerra",
                    value: 3,
                },
                {
                    name: "SummitLab",
                    value: 4,
                },
                // {
                //   name: "SummitPixel",
                //   value: 5,
                // },
                // {
                //   name: "OSlamWorks",
                //   value: 6,
                // },
            ],
            actionsTrialVersion: [
                {
                    name: "是",
                    value: 0,
                },
                {
                    name: "否",
                    value: "2",
                },
            ],
            dogID: '',
            permitRes: false,
            permitSoftId: '',
            permitSoftName: ''
        };
    },
    mounted() {
        // 判断该手机号该机器码是否已经在申请
        // 填写信息 申请
        this.urlData = this.$route.query;
        this.machineCode = this.$route.query.id;
        if (this.$route.query.phoneNum || this.$route.query.des) {
            this.$route.query.phoneNum = '';
            this.$route.query.des = '';
        }
        if (this.$route.query.type && this.$route.query.type == 2) {
            this.trialVersionTitle = "试用版";
            this.type = 0;
        } else {
            this.trialVersionTitle = "正式";
            this.type = 2;
        }
        if (this.$route.query.dogID) {
            // 存在加密狗数据
            this.dogID = this.$route.query.dogID;
        }
        console.log(this.urlData, 'sss');
        if (this.urlData.pid == undefined) {
            // this.xkShow = false;
            // this.phone = this.urls.phone;
            // this.exhibit = false;
            // this.querys();
        } else {
            if (this.urlData.pid == 1) {
                this.softName = "SummitMap";
            } else if (this.urlData.pid == 2) {
                this.softName = "SummitSolid";
            } else if (this.urlData.pid == 4) {
                this.softName = "SummitLab";
            } else if (this.urlData.pid == 5) {
                this.softName = "SummitMapHUBEI";
            } else if (this.urlData.pid == 6) {
                this.softName = "SummitLab";
            } else if (this.urlData.pid == 7) {
                this.softName = "SummitSolidSGJ";
            } else if (this.urlData.pid == 8) {
                this.softName = "SummitTerra";
            } else if (this.urlData.pid == 10) {
                this.softName = "SummitViewPro";
            }
            this.cellTitle = this.softName;
        }
    },
    // components: {
    //     [Notify.Component.name]: Notify.Component,
    // },
    watch: {
        permitcode(val) {
            if (val.length >= 6) {
                // 判断许可码
                this.checkPermit();
            } else {
                this.permitRes = false;
            }
        }
    },
    computed: {
        disabled() {
            if (this.phoneNum && this.machineCode && this.urlData.pid && this.type !== "" && this.name && this.company) {
                return false;
            } else {
                return true;
            }
        }
    },
    methods: {
        onActive() {
            this.show = true;
        },
        onSelect(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show = false;
            this.cellTitle = item.name;
            this.urlData.pid = item.value;
            //   Toast(item.name);
        },
        onCancel() {
            this.show = false;
        },
        onActiveTrialVersion() {
            this.showVersion = true;
        },
        onSelectTrialVersion(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.showVersion = false;
            this.trialVersionTitle = item.name;
            this.type = item.value;
            //   Toast(item.name);
        },
        onCancelTrialVersion() {
            this.showVersion = false;
        },
        onClickLeft() {
            // 返回列表选择
            this.$router.push({
                name: "ApplyHome", path: "/ApplyHome", query: this.$route.query
            });
        },
        searchLicense() {
            this.$route.query.phoneNum = this.phoneNum;
            let query = this.$route.query;
            this.$router.push({
                name: "applylistm", path: "/applylistm", query
            });
        },
        getProductid(type) {
            let nameList = this.permitSoftName.split(',');
            let idList = this.permitSoftId.split(',');
            let resIds = [];
            for (let i = 0; i < nameList.length; i++) {
                const e = nameList[i];
                if (e == type) {
                    resIds.push(idList[i]);
                }
            }
            return resIds;
        },
        createLicense() {
            // 许可码审核通过，生成许可证
            // 确认验证码是否正确
            let producttype = '';
            let productid = ''; // todo
            if (this.urlData.pid == 1) {
                producttype = 'SummitMap';
            } else if (this.urlData.pid == 2) {
                producttype = 'SummitSolid';
            } else if (this.urlData.pid == 3) {
                producttype = 'SummitTerra';
            } else if (this.urlData.pid == 4) {
                producttype = 'SummitLab';
            } else if (this.urlData.pid == 5) {
                producttype = "SummitMapHUBEI";
            } else if (this.urlData.pid == 6) {
                producttype = "SummitLab";
            } else if (this.urlData.pid == 7) {
                producttype = "SummitSolidSGJ";
            } else if (this.urlData.pid == 8) {
                producttype = "SummitTerra";
            } else if (this.urlData.pid == 10) {
                producttype = "SummitViewPro";
            }
            productid = this.getProductid(producttype);
            let _query = {
                producttype: producttype,
                state: 0,
                machinecode: this.machineCode,
                contactphone: this.phoneNum,
                contactname: this.name,
                companyname: this.company,
                guestmes: this.des,
                lastmodifytime: new Date().getTime(),
                licensetype: this.type,
                permitcode: this.permitcode,
                productid: productid.toString()
            };
            console.log(_query, 'data');
            ApplyLicense(_query).then(res => {
                if (res.code == 200) {
                    // 调用生成接口
                    this.createLicenseLock(res.data);
                }
            });
            // if (!this.sms) {
            //     Notify({ type: 'danger', message: '请填写验证码' });
            //     return;
            // }
            // CheckVerCode(this.phoneNum, this.sms).then(res => {
            //     if (res.code != 200) {
            //         Notify({ type: 'danger', message: res.msg });
            //     } else {
            //         console.log('create');
            //         let producttype = '';
            //         if (this.urlData.pid == 1) {
            //             producttype = 'SummitMap';
            //         } else if (this.urlData.pid == 2) {
            //             producttype = 'SummitSolid';
            //         } else if (this.urlData.pid == 3) {
            //             producttype = 'SummitTerra';
            //         } else if (this.urlData.pid == 4) {
            //             producttype = 'SummitLab';
            //         } else if (this.urlData.pid == 5) {
            //             producttype = "SummitMapHUBEI";
            //         }
            //         let _query = {
            //             producttype: producttype,
            //             state: 0,
            //             machinecode: this.machineCode,
            //             contactphone: this.phoneNum,
            //             contactname: this.name,
            //             companyname: this.company,
            //             guestmes: this.des,
            //             lastmodifytime: new Date().getTime(),
            //             licensetype: this.type,
            //             permitcode: this.permitcode
            //         };
            //         console.log(_query, 'data');
            //         ApplyLicense(_query).then(res => {
            //             if (res.code == 200) {
            //                 // 调用生成接口
            //                 this.createLicenseLock(res.data);
            //             }
            //         });
            //     }
            // });
        },
        createLicenseLock(data) {
            // licensetype 2 线下 0 免费
            console.log(data, 'uuuu');
            let producttypeD = data.producttype;
            var idsResult = data.productid;
            var featureidR = '';
            // var durationR = 30;
            if (data.licensetype == 2) {
                if (data.producttype == 'SummitMapHUBEI') {
                    // idsResult = "538282264836965957";
                    featureidR = '5';
                } else if (data.producttype == 'SummitMap') {
                    // idsResult = "534458548269169797";
                    featureidR = '1';

                } else if (data.producttype == 'SummitSolid') {
                    // idsResult = "512600037742674693";
                    featureidR = '2';

                } else if (data.producttype == 'SummitLab') {
                    // idsResult = "534460508426812549";
                    featureidR = '4';

                }
                // durationR = 60;
            } else if (data.licensetype == 0) {
                if (data.producttype == 'SummitMapHUBEI') {
                    featureidR = '5';

                } else if (data.producttype == 'SummitMap') {
                    idsResult = '538329116873591365';
                    featureidR = '1';

                } else if (data.producttype == 'SummitSolid') {
                    idsResult = '538328828611660357';
                    featureidR = '2';

                } else if (data.producttype == 'SummitLab') {
                    idsResult = '538328542602070597';
                    featureidR = '4';
                }
                producttypeD = data.producttype + '-Trial';
            }
            let data1 = {
                productid: idsResult,
                featureid: featureidR,
                producttype: producttypeD,
                state: 0,
                filedata: '',
                duration: this.durationRes,
                machinecode: data.machinecode,
                contactphone: data.contactphone,
                contactname: data.contactname,
                companyname: data.companyname,
                guestmes: data.guestmes,
                endtime: '',
                licensetype: data.licensetype,
                id: data.id,
                dogid: this.dogID
            };

            request({
                url: '/api/business/C_license/makeChecklicense',
                method: 'post',
                data: data1
            }).then(res => {
                // 刷新数据
                if (res.code == 200) {
                    Dialog.alert({
                        message: '许可证生成成功',
                        theme: 'round-button',
                    }).then(() => {
                        // on close 跳转到查询页
                        let query = {
                            id: this.machineCode,
                            phoneNum: this.phoneNum,
                        };
                        this.$router.push({
                            name: "applylistm", path: "/applylistm", query
                        });
                    });
                } else {
                    Notify({ type: 'danger', message: "许可证生成失败" });
                }
            });
        },
        onSubmit() {
            // if(this.phoneNum != null){

            // }
            // 确认验证码是否正确
            // if (!this.sms) {
            //     Notify({ type: 'danger', message: '请填写验证码' });
            //     return;
            // }
            // CheckVerCode(this.phoneNum, this.sms).then(res => {
            //     if (res.code != 200) {
            //         Notify({ type: 'danger', message: res.msg });
            //     } else {
            let producttype = '';
            if (this.urlData.pid == 1) {
                producttype = 'SummitMap';
            } else if (this.urlData.pid == 2) {
                producttype = 'SummitSolid';
            } else if (this.urlData.pid == 3) {
                producttype = 'SummitTerra';
            } else if (this.urlData.pid == 4) {
                producttype = 'SummitLab';
            } else if (this.urlData.pid == 5) {
                producttype = "SummitMapHUBEI";
            } else if (this.urlData.pid == 6) {
                producttype = "SummitLab";
            } else if (this.urlData.pid == 7) {
                producttype = "SummitSolidSGJ";
            } else if (this.urlData.pid == 8) {
                producttype = "SummitTerra";
            } else if (this.urlData.pid == 10) {
                producttype = "SummitViewPro";
            }
            let _query = {
                producttype: producttype,
                state: 0,
                machinecode: this.machineCode,
                contactphone: this.phoneNum,
                contactname: this.name,
                companyname: this.company,
                guestmes: this.des,
                lastmodifytime: new Date().getTime(),
                licensetype: this.type,
                dogid: this.dogID
            };


            // 发起申请
            console.log(_query, 'data');
            ApplyLicense(_query).then(res => {
                if (res.code == 200) {
                    console.log("成功");
                    Dialog.alert({
                        message: '申请成功，请耐心等待审核，许可加急通过请拨打：13807196636',
                        theme: 'round-button',
                    }).then(() => {
                        // on close 跳转到查询页
                        let query = {
                            id: this.machineCode,
                            phoneNum: this.phoneNum,
                        };
                        this.$router.push({
                            name: "applylistm", path: "/applylistm", query
                        });
                    });
                }
            });
            // }
            // });
        },
        rewrite() {
            this.phoneNum = '';
            this.name = '';
            this.company = '';
            this.inviteCode = '';
            this.des = '';
        },
        usernameInput() {
            const re = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
            if (re.test(this.phoneNum) && this.phoneNum * 1 != "NaN") {
                if (this.totalTime < 60) {
                    this.disable = true;
                }
                this.disable = false;
            } else {
                this.disable = true;
            }
        },
        sendCode() {
            this.disable = true;
            SendVerCode(this.phoneNum).then(res => {
                if (res.code == 200) {
                    // 发送成功
                    Notify({ type: 'success', message: '发送成功!' });
                    this.yzm = this.totalTime + "s后重新发送";
                    const clock = setInterval(() => {
                        this.yzm = this.totalTime-- + "s后重新发送";
                        if (this.totalTime < 0) {
                            clearInterval(clock);
                            this.disable = false;
                            this.yzm = "重新发送验证码";
                            this.totalTime = 60;
                        }
                    }, 1000);
                } else {
                    // 发送失败
                    Notify({ type: 'warning', message: res.msg });
                }
            });
        },
        checkPermit() {
            if (this.permitcode) {
                let query = {
                    permitcode: this.permitcode,
                    enablemark: 1
                };
                checkPermit(query).then(res => {
                    if (res.data.list.length == 1) {
                        let codeData = res.data.list[0];
                        // 判断是否适用 有加密狗 判断获取的dogID
                        // 根据pid 判断软件
                        let result = true;
                        if (codeData.dogid) {
                            let dogData = codeData.dogid.split(',');
                            let baseDog = this.dogID.split('_'); // 829200000237_829200000362_Net829200000010
                            if (this.dogID.includes('Net')) {
                                let changeDogList = [];
                                baseDog.forEach(ele => {
                                    if (ele.includes('Net')) {
                                        changeDogList.push(ele.slice(3));
                                    } else {
                                        changeDogList.push(ele);
                                    }
                                });
                                baseDog = changeDogList;
                            }
                            let res = (dogData.length + baseDog.length) !== Array.from(new Set([...dogData, ...baseDog])).length; // 求交集
                            if (!res) {
                                result = false;
                            }
                        }
                        if (codeData.softid) {
                            let producttype = '';
                            if (this.urlData.pid == 1) {
                                producttype = 'SummitMap';
                            } else if (this.urlData.pid == 2) {
                                producttype = 'SummitSolid';
                            } else if (this.urlData.pid == 3) {
                                producttype = 'SummitTerra';
                            } else if (this.urlData.pid == 4) {
                                producttype = 'SummitLab';
                            } else if (this.urlData.pid == 5) {
                                producttype = "SummitMapHUBEI";
                            } else if (this.urlData.pid == 6) {
                                producttype = "SummitLab";
                            } else if (this.urlData.pid == 7) {
                                producttype = "SummitSolidSGJ";
                            } else if (this.urlData.pid == 8) {
                                producttype = "SummitTerra";
                            } else if (this.urlData.pid == 10) {
                                producttype = "SummitViewPro";
                            }
                            let softRes = codeData.softname.split(',').includes(producttype);
                            if (!softRes) {
                                result = false;
                            }
                        }
                        // 判断使用时间 判断使用次数
                        if (codeData.starttime && codeData.endtime) {
                            let nowDate = Date.parse(new Date());
                            if (nowDate < codeData.starttime || nowDate > codeData.endtime) {
                                result = false;
                            }
                        }
                        if (codeData.count > codeData.usedCount) {

                        } else {
                            result = false;
                        }

                        if (result) {
                            // 判断通过
                            this.permitRes = true;
                            this.permitSoftName = codeData.softname;
                            this.permitSoftId = codeData.softid;
                            this.durationRes = codeData.duration;
                            Notify({ type: 'success', message: "许可码有效！" });

                        } else {
                            Notify({ type: 'warning', message: "无权限" });
                        }


                    } else {
                        Notify({ type: 'warning', message: "许可码失效" });
                    }
                });
            }

        }
    },
};
</script>

<style lang="scss" scoped>
.home {}

.navbar {
    ::v-deep .van-nav-bar__right {
        .van-nav-bar__text {
            color: #808080;
        }
    }
}

.van-form {
    background-color: rgb(229, 229, 229, 0.5);
    padding-top: 5px;

}

.myvanCell {
    border-radius: 10px;
    margin: 7px;
    margin-top: 0px;
    overflow: hidden;
    width: auto;
}

.machineStyle {
    display: block;

    ::v-deep .van-field__control {
        min-height: auto;
        background-color: rgba(43, 133, 229, 0.1);
        text-align: center;
        margin: 5px 0;
    }

}
</style>